





























import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
//  components
import PowerLimitBreachesTable from "@/components/Shapoli/PowerLimitBreaches/PowerLimitBreachesTable.vue";
import PowerLimitBreachesChart from "@/components/Shapoli/PowerLimitBreaches/PowerLimitBreachesChart.vue";
//  types
import { ShapoliEvent } from "@/types/ShapoliEvent";
import { ShapoliBreach } from "@/types/ShapoliBreach";
import { LogData } from "@/types/logData";

@Component({
  components: {
    PowerLimitBreachesTable,
    PowerLimitBreachesChart,
  },
})
export default class PowerLimitBreaches extends Vue {
  @Prop({ required: true }) events!: ShapoliEvent[];
  @Prop({ required: true }) breaches!: ShapoliBreach[];
  @Prop({ required: true }) shaftPowerData!: Partial<LogData>;
  @Prop({ required: true }) shaftPowerLimit!: number;
  @PropSync("hasEventsOnChart", { type: Boolean, default: false }) showEvents!: boolean;
  @PropSync("breachesDataLoader", { type: Boolean, default: false }) breachesDataLoading!: boolean;

  onEventRowClicked(breachId: string): void {
    this.$emit("onRowClicked", breachId);
  }

  onEventChartZoom(event: any): void {
    this.$emit("onChartZoom", event);
  }
}
