
















































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
//  types
import { ShapoliEvent } from "@/types/ShapoliEvent";
import { ShapoliBreach } from "@/types/ShapoliBreach";
import { ExtendedVessel } from "@/types/Vessel";
import { LogData } from "@/types/logData";

//  components
import ShapoliContentLoader from "@/components/Shapoli/ShapoliContentLoader.vue";
import VesselInfoContentCard from "@/components/VesselInfo/VesselInfoContentCard.vue";
import PowerLimitBreaches from "@/components/Shapoli/PowerLimitBreaches/index.vue";
import PowerLimitBreachesActions from "@/components/Shapoli/PowerLimitBreaches/PowerLimitBreachesActions.vue";
import Events from "@/components/Shapoli/Events.vue";
import Map from "@/components/Map.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";

//  clients
import LogDataClient from "Clients/log-data-client";
import ShapoliClient from "Clients/shapoli-client";

//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import ShapoliModule from "@/store/clients/Shapoli.module";
import LogVariablesModule from "@/store/clients/LogVariables.module";
import LogDataModule from "@/store/clients/LogData.module";
import { LogVariable } from "@/types/logVariable";

const Vessels = getModule(VesselsModule, store);
const Shapoli = getModule(ShapoliModule, store);
const LogVariables = getModule(LogVariablesModule, store);
const LogData = getModule(LogDataModule, store);

@Component({
  components: {
    ShapoliContentLoader,
    VesselInfoContentCard,
    PowerLimitBreaches,
    PowerLimitBreachesActions,
    Events,
    Map,
    FeatureNotAvailable,
  },
})
export default class ShapoliView extends Vue {
  breachDetailsDialog = false;
  breach: any = null;
  vesselShaftPowerLogVariables: LogVariable[] = [];
  shaftPowerData: Partial<LogData> = {};
  year: number = new Date().getFullYear(); // current year by default
  loader = true;
  breachesDataLoader = false;
  hasEventsOnChart = false;
  breachLogVariablesAverage: Partial<LogVariable>[] = [];
  breachLatLngHistory: { longitude: number; latitude: number }[] = [];

  //  @Getters
  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "Shapoli");
  }

  get isLoading(): boolean {
    return Boolean(this.shaftPowerData);
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get vesselLogVariables(): LogVariable[] {
    return LogVariables.currentVesselLogVariables;
  }

  get events(): ShapoliEvent[] | null {
    return Shapoli.events;
  }

  get breaches(): ShapoliBreach[] | null {
    return Shapoli.breaches;
  }

  get unit(): { caption: string; name: string } {
    return this.shaftPowerData.unit ?? { caption: "", name: "" };
  }

  get generalInfo(): { description: string; value: number | string | undefined }[] | null {
    if (!this.vessel) return null;
    return [
      { description: "Vessel type", value: this.vessel?.vesselType?.name ?? "N/A" },
      { description: "DWT", value: this.vessel.deadweight ?? "N/A" },
      { description: "Gross tonnage", value: this.vessel.grossTonnage ?? "N/A" },
      { description: "Unlimited Shaft Power", value: `${this.vessel.maxContinuousRating} kW` },
      { description: "Limited Shaft Power", value: this.vessel.shaftPowerLimit ? `${this.vessel.shaftPowerLimit} kW` : "No Data" },
    ];
  }

  get breachDetails(): { name: string; value: string | undefined }[] | null {
    if (!this.breach) return null;
    return [
      { name: "ID", value: this.breach.id },
      { name: "Start Date", value: dateHelper.getFormatedDateTimeString(this.breach.startDateUtc) },
      { name: "End Date", value: dateHelper.getFormatedDateTimeString(this.breach.endDateUtc) },
      { name: "Start Position", value: this.breach.startPosition },
      { name: "End Position", value: this.breach.endPosition },
      { name: `Reason (${this.breach.reason ? this.breach.reason : "Unspecified"})`, value: this.breach.comment },
    ];
  }

  get breachDuration(): string {
    const from = moment(this.breach.endDateUtc, "YYYY-MM-DDTHH:mm:ss.SSS");
    const to = moment(this.breach.startDateUtc, "YYYY-MM-DDTHH:mm:ss.SSS");
    return dateHelper.getDurationBetweenDates(from, to);
  }

  get selectedYearAsFromToDates(): { fromDate: string; toDate: string } {
    const momentDateObject = moment().year(this.year);
    if (this.year !== new Date().getFullYear()) {
      return {
        fromDate: moment(momentDateObject).startOf("year").format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toDate: moment(momentDateObject).endOf("year").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      };
    }

    // YTD
    return {
      fromDate: moment(momentDateObject).startOf("year").format("YYYY-MM-DDTHH:mm:ss.SSS"),
      toDate: moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS"),
    };
  }

  //  @Methods
  async onRowClicked(breachId: string): Promise<void> {
    if (!this.vessel) return;
    this.breachDetailsDialog = true;

    //  set breach and fetch average data
    this.breach = this.breaches?.find(item => item.id === breachId);
    const averageData: any = await LogData.fetchShapoliAveraged({
      vesselId: this.vessel.id,
      fromDate: this.breach.startDateUtc,
      toDate: this.breach.endDateUtc,
    });
    for (const [key, value] of Object.entries(averageData)) {
      const logVariable: any = this.vesselLogVariables.find(v => v.id === Number(key));
      if (!logVariable) return;
      logVariable.averageValue = value;
      this.breachLogVariablesAverage.push(logVariable);
    }

    //  set latitude and longitude log variables and fetch log data
    const vesselLatitudeLogVariables = this.vesselLogVariables.find(v => v.name === "Latitude");
    const vesselLongitudeLogVariables = this.vesselLogVariables.find(v => v.name === "Longitude");
    if (!vesselLatitudeLogVariables && !vesselLongitudeLogVariables) return;
    const dates = { fromDate: this.breach.startDateUtc, toDate: this.breach.endDateUtc };
    const selectedTimeInMinutes = this.minutesDiff(new Date(dates.fromDate), new Date(dates.toDate));
    const lat = await LogDataClient.findLogData(vesselLatitudeLogVariables?.id, dates.fromDate, dates.toDate, this.autoGranularity(selectedTimeInMinutes));
    const lng = await LogDataClient.findLogData(vesselLongitudeLogVariables?.id, dates.fromDate, dates.toDate, this.autoGranularity(selectedTimeInMinutes));
    this.breachLatLngHistory = this.getBreachLatLngHistory(lat.data, lng.data);
  }

  async onChartZoom(event: any): Promise<void> {
    if (!this.vessel) return;
    if (event.resetSelection) {
      await this.fetchShaftPowerData(this.vessel.id, this.selectedYearAsFromToDates);
    } else {
      const dates = {
        fromDate: moment.utc(event.xAxis[0].min).format("YYYY-MM-DDTHH:mm:ss.SSS"),
        toDate: moment.utc(event.xAxis[0].max).format("YYYY-MM-DDTHH:mm:ss.SSS"),
      };
      const minutesDiff = this.minutesDiff(new Date(dates.fromDate), new Date(dates.toDate));
      if (minutesDiff >= 5) await this.fetchShaftPowerData(this.vessel.id, dates);
    }
  }

  getBreachLatLngHistory(latLogVariablesData: any, lngLogVariablesData: any): { longitude: number; latitude: number }[] {
    const latLng: { longitude: number; latitude: number }[] = [];
    Object.keys(latLogVariablesData).forEach(key => {
      latLng.push({ latitude: latLogVariablesData[key], longitude: lngLogVariablesData[key] });
    });
    return latLng;
  }

  async onYearChange(year: number): Promise<void> {
    this.year = year;
    if (!this.vessel || !this.year) return;
    await this.fetchData();
  }

  convertAccordingToUnitType(value: number, unit: string): number {
    if (unit.toLowerCase() === "mw") return Math.round(value / 1000);
    return value;
  }

  async fetchShaftPowerData(vesselId: number, dates: { fromDate: string; toDate: string }): Promise<void> {
    if (!this.vessel) return;
    this.vesselShaftPowerLogVariables = (await LogVariables.fetchVesselShaftPowerLogVariables(vesselId)) ?? [];
    const selectedTimeInMinutes = this.minutesDiff(new Date(dates.fromDate), new Date(dates.toDate));
    if (!this.vesselShaftPowerLogVariables.length) return;
    //  check if it has more then 1 shaft and fetch log data for each
    if (this.vesselShaftPowerLogVariables.length > 1) {
      const shaftsPowerLogData: LogData[] = [];
      for (const shaftPowerLogVariable of this.vesselShaftPowerLogVariables.values()) {
        shaftsPowerLogData.push(await LogDataClient.findLogData(shaftPowerLogVariable.id, dates.fromDate, dates.toDate, this.autoGranularity(selectedTimeInMinutes)));
      }
      // calculate average data of two shafts
      const averageDataObject: any = {};
      Object.entries(shaftsPowerLogData[0].data).forEach(([timestamp, value]) => {
        const firstShaftValue = Number(value);
        const secondShaftValue = Number(shaftsPowerLogData[1].data[timestamp]);

        const averageValue = (firstShaftValue + secondShaftValue) / 2;
        averageDataObject[timestamp] = averageValue;
      });
      this.shaftPowerData = Object.assign({}, this.shaftPowerData, { data: averageDataObject, unit: shaftsPowerLogData[0].unit });
    } else {
      const logData = await LogDataClient.findLogData(this.vesselShaftPowerLogVariables[0].id, dates.fromDate, dates.toDate, this.autoGranularity(selectedTimeInMinutes));
      this.shaftPowerData = Object.assign({}, this.shaftPowerData, logData);
    }
  }

  autoGranularity(selectedTimeInMinutes: number): string {
    if (selectedTimeInMinutes <= 1440) {
      return "Raw";
    } else if (selectedTimeInMinutes <= 2880) {
      return "Minute";
    } else if (selectedTimeInMinutes <= 20160) {
      return "QuarterHour";
    } else if (selectedTimeInMinutes <= 129600) {
      return "Hour";
    }

    return "Day";
  }

  minutesDiff(fromDate: Date, toDate: Date): number {
    const diff = (fromDate.getTime() - toDate.getTime()) / 1000 / 60;

    return Math.abs(Math.round(diff));
  }

  async fetchData(): Promise<void> {
    if (!this.vessel) return;
    this.breachesDataLoader = true;
    await this.fetchShaftPowerData(this.vessel.id, this.selectedYearAsFromToDates);
    await Shapoli.fetchBreaches({ vesselId: this.vessel.id, year: this.year });
    await Shapoli.fetchEvents({ vesselId: this.vessel.id, year: this.year });
    this.breachesDataLoader = false;
  }

  async fetchLogVariables(): Promise<void> {
    if (!this.vessel?.id) return;
    await LogVariables.refreshCurrentVesselLogVariables(this.vessel.id);
  }

  downloadBreachLogData(): void {
    if (!this.vessel) return;
    const dates = {
      fromDate: this.breach.startDateUtc,
      toDate: this.breach.endDateUtc,
    };
    ShapoliClient.downloadBreachLogData(this.vessel.id, dates);
  }

  onDownloadBreachesYearReportClicked(): void {
    if (!this.vessel) return;
    ShapoliClient.downloadBreachesYearReport(this.vessel.id, this.year);
  }

  getDateTimeString(date: string): string {
    return dateHelper.getFormatedDateTimeString(date);
  }

  closeDialog(): void {
    this.breachDetailsDialog = false;
    this.breachLogVariablesAverage = [];
    this.breachLatLngHistory = [];
  }

  //  @Hooks
  async created(): Promise<void> {
    if (!this.vessel || !this.featureEnabled) return;
    await this.fetchData();
    await this.fetchLogVariables();
    this.loader = false;
  }
}
