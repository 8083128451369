













import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
// libraries
import moment from "moment";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import dateHelper from "@/Scripts/utilities/date-helper";
//  types
import { IPlotLine } from "@/types/highcharts/plotLine";
import { ShapoliEvent } from "@/types/ShapoliEvent";
import { ShapoliBreach } from "@/types/ShapoliBreach";
import { LogData } from "@/types/logData";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class PowerLimitBreachesChart extends Vue {
  @Prop({ required: true }) breaches!: ShapoliBreach[];
  @Prop({ required: true }) events!: ShapoliEvent[];
  @Prop({ required: true }) shaftPowerData!: Partial<LogData>;
  @Prop({ required: true }) shaftPowerLimit!: number;
  @PropSync("showEvents", { type: Boolean, default: false }) hasEventsOnChart!: boolean;
  @Prop() resizing!: boolean;

  chart!: any;
  chartLoaded = false;

  //  need to call reflow on the chart with some delay after widget resize, otherwise it will not resize properly
  reflowTimer!: number;
  @Watch("resizing")
  resized(val: boolean): void {
    this.reflowTimer = setTimeout(() => {
      if (!val && this.chart) {
        this.chart.update(this.ChartOptions, true);
        this.chart.reflow();
      }
    }, 300);
  }

  @Watch("shaftPowerData", { deep: true })
  onShaftPowerDataChanged(): void {
    if (!this.chart) return;
    this.chart.update(this.ChartOptions, true);
  }

  @Watch("hasEventsOnChart")
  onShowEventsOnChartChanged(): void {
    setTimeout(() => {
      this.chart.update(this.ChartOptions, true);
      this.chart.reflow();
    }, 300);
  }

  get isShaftPowerDataEmtpy(): boolean {
    if (!this.shaftPowerData.data) return true;
    return Boolean(Object.keys(this.shaftPowerData.data).length === 0);
  }

  get unit(): { caption: string; name: string } {
    return this.shaftPowerData.unit ?? { caption: "", name: "" };
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    return {
      type: "area",
      zoomType: "x",
      spacingTop: 30,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      style: { fontFamily: "Helvetica Neue" },
      events: {
        selection: function (event: any) {
          //  update shaft power limit horizontal plot line
          setTimeout(() => {
            if (!ctx.shaftPowerLimit) return;
            if (ctx.shaftPowerLimit > event.target.yAxis[0].dataMax) {
              ctx.ChartOptions.plotOptions.series.fillColor.stops = ctx.HighchartsNoOverlimitBackgroundColor;
            } else {
              ctx.ChartOptions.plotOptions.series.fillColor.stops = ctx.HighchartsHasOverlimitBackgroundColor;
            }
            ctx.chart.update(ctx.ChartOptions, true);
          });

          //  trigger onChartZoom event on zoom
          ctx.$emit("onEventChartZoom", event);
        },
      },
    };
  }

  get ChartOptions(): any {
    if (!this.chartLoaded || !Highcharts) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: { text: "" },
      legend: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: "",
          style: { color: "#331714" },
        },
        labels: {
          format: `{value} ${ctx.unit.caption}`,
          style: { color: "#331714" },
        },
        plotLines: [
          {
            color: "#ff5252",
            value: this.shaftPowerLimit,
            width: 2,
            zIndex: 4,
          },
        ],
        ...this.yAxisMinMaxValues,
      },
      xAxis: {
        type: "datetime",
        labels: {
          style: { color: "#331714" },
        },
        plotLines: [...this.vesselEventPlotLines, ...this.vesselBreachesPlotLines],
      },
      plotOptions: {
        area: {
          threshold: 0,
          marker: {
            enabled: false,
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
          fillColor: {
            linearGradient: [0, 60, 0, 135],
            stops: this.gradientBackgroundColors,
          },
        },
      },
      tooltip: {
        formatter: function () {
          const $this: any = this;
          return `
                        <b style="margin-bottom: 5px;">${dateHelper.getFormatedDateTimeString($this.point.x, "HH:mm:ss")}</b> <br>
                        <span>Shaft Power: ${Number($this.point.y.toFixed(2))} ${ctx.unit.caption}</span>`;
        },
      },
      series: [
        {
          data: this.seriesData,
        },
      ],
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get seriesData(): any {
    if (!this.shaftPowerData.data) return;
    const series = Object.entries(this.shaftPowerData.data).map(([timestamp, value]) => {
      return [Date.parse(`${timestamp}Z`), value !== null ? Number(value) : null];
    });
    return series;
  }

  get HighchartsNoOverlimitBackgroundColor(): any[] {
    return [[1, Highcharts.color("#2d9b2d").setOpacity(0.2).get("rgba"), 20]];
  }

  get HighchartsHasOverlimitBackgroundColor(): any[] {
    return [
      [0, "red"],
      [1, Highcharts.color("#2d9b2d").setOpacity(0.2).get("rgba"), 20],
    ];
  }

  get gradientBackgroundColors(): any {
    if (!this.hasOverlimitedData) return this.HighchartsNoOverlimitBackgroundColor;
    return this.HighchartsHasOverlimitBackgroundColor;
  }

  get vesselEventPlotLines(): IPlotLine[] {
    if (!this.events || !this.hasEventsOnChart) return [];
    const plotLines: IPlotLine[] = [];

    this.events.forEach(event => {
      const html = this.getPlotLineLabelsHtml(this.getVesselEventsOnSameDate(event.utcTimestamp), event);
      plotLines.push({
        id: `plotline-${event.id}`,
        color: "#2196F3",
        value: moment.utc(event.utcTimestamp).valueOf(),
        width: 2,
        zIndex: 5,
        label: {
          rotation: 0,
          style: { color: "#2196F3" },
          text: html,
          useHTML: true,
          y: -15,
          x: -11,
        },
      });
    });
    return plotLines;
  }

  get vesselBreachesPlotLines(): IPlotLine[] {
    if (!this.breaches) return [];
    const plotLines: IPlotLine[] = [];

    this.breaches.forEach(breach => {
      plotLines.push(
        {
          id: `plotline-${breach.id}`,
          color: "#ff5252",
          value: moment.utc(breach.startDateUtc).valueOf(),
          width: 2,
          zIndex: 10,
          label: {
            rotation: 0,
            style: { color: "#ff5252" },
            text: this.getBreachPlotLineLabelsHtml(breach),
            useHTML: true,
            y: -15,
            x: -11,
          },
        },
        {
          id: `plotline-${breach.id}`,
          color: "#ff5252",
          value: moment.utc(breach.endDateUtc).valueOf(),
          width: 2,
          zIndex: 10,
          label: {
            rotation: 0,
            style: { color: "#ff5252" },
            text: this.getBreachPlotLineLabelsHtml(breach, "end"),
            useHTML: true,
            y: -15,
            x: -11,
          },
        }
      );
    });
    return plotLines;
  }

  get hasOverlimitedData(): boolean {
    if (this.shaftPowerLimit === 0 || this.shaftPowerLimit === null) return false;
    return this.seriesData.some((el: number[]) => el[1] > this.shaftPowerLimit);
  }

  getVesselEventsOnSameDate(eventTimestamp: string): any {
    return this.events.filter((vesselEvent: any) => vesselEvent.utcTimestamp === eventTimestamp);
  }

  getPlotLineLabelsHtml(vesselEventsOnSameDate: any, event: any): string {
    return `
                  <span class="plot-line-label-icon mdi mdi-alpha-e-circle" style="position: relative; z-index: 5;">
                    <i
                      class="multiple-event-counter"
                      style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
                    >
                      ${vesselEventsOnSameDate.length}
                    </i>
                  </span>
                  <div class="plot-line-tooltip">
                    ${vesselEventsOnSameDate
                      .map(
                        (vesselEvent: any, index: number) => `
                      <p style="margin-bottom: 10px;">
                        <i
                          class="plot-line-label-icon mdi mdi-alpha-e-circle mr-2"
                          style="display: ${vesselEventsOnSameDate.length >= 2 ? "block" : "none"}"
                        ></i>
                        <b>${dateHelper.getFormatedDateTimeString(vesselEvent.utcTimestamp, "HH:mm:ss")} <span>|</span> ${vesselEvent.eventType}</b>
                      </p>
                      <p>${vesselEvent.message}</p>
                      <hr class="my-2" style="display: ${vesselEventsOnSameDate.length - 1 === index ? "none" : "block"}" />
                    `
                      )
                      .join("")}
                  </div>
                `;
  }

  getBreachPlotLineLabelsHtml(breach: ShapoliBreach, timestamp = "start"): string {
    return `
                  <span class="plot-line-label-icon mdi mdi-alpha-b-circle" style="position: relative; z-index: 10;"></span>
                  <div class="plot-line-tooltip">
                    <p><b>ID: ${breach.id}</b></p>
                    <p style="margin-bottom: 10px;">
                      <b style="text-transform: capitalize;">${timestamp} date: ${
      timestamp === "start" ? dateHelper.getFormatedDateTimeString(breach.startDateUtc, "HH:mm:ss") : dateHelper.getFormatedDateTimeString(breach.endDateUtc, "HH:mm:ss")
    } <span>|</span> Reason: ${breach.reason}</b>
                    </p>
                    <p>${breach.comment}</p>
                  </div>
                `;
  }

  yAxisMinMaxValues(seriesIndex: number): { max: number; min: number } | null {
    if (!this.shaftPowerData.data) return null;
    const yAxisValues = Object.entries(this.shaftPowerData.data).map(([timestamp, pairValues]) => (pairValues[seriesIndex] !== null ? Number(pairValues[seriesIndex].toFixed(2)) : 0));
    if (yAxisValues === null) return yAxisValues;
    return {
      min: Math.min(...yAxisValues),
      max: Math.max(...yAxisValues),
    };
  }

  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
    this.hasOverlimitedData;
  }

  onWindowResize(): void {
    setTimeout(() => {
      this.chart.reflow();
    }, 200);
  }

  created(): void {
    window.addEventListener("resize", this.onWindowResize);
  }

  beforeDestroy(): void {
    if (this.reflowTimer) clearTimeout(this.reflowTimer);
    window.removeEventListener("resize", this.onWindowResize);
  }
}
