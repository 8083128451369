
export interface TableHeader {
  text: string | undefined;
  value: string;
  sortable?: boolean;
  width?: string;
}

export interface TableRow {
  id: string;
  acknowledgedDateUtc: string | null;
  startDateUtc: string;
  startPosition: string;
  endDateUtc: string;
  endPosition: string;
  silencedDateUtc: string | null;
  reason: string | null;
  reasonUpdatedUtcTimeStamp: string | null;
  comment: string | null;
}
export const headers: TableHeader[] = [
  {
    text: "ID",
    value: "id",
    sortable: true,
    width: "50px",
  },
  {
    text: "Start Date (UTC)",
    value: "startDateUtc",
    sortable: true,
    width: "135px",
  },
  {
    text: "Start Position",
    value: "startPosition",
    sortable: true,
    width: "150px",
  },
  {
    text: "End Date (UTC)",
    value: "endDateUtc",
    sortable: true,
    width: "135px",
  },
  {
    text: "End Position",
    value: "endPosition",
    sortable: true,
    width: "150px",
  },
  {
    text: "Reason",
    value: "reason",
    sortable: true,
    width: "135px",
  },
  {
    text: "Reason updated",
    value: "reasonUpdatedUtcTimeStamp",
    sortable: true,
    width: "135px",
  },
];
