






























































import { Component, Vue, Prop } from "vue-property-decorator";
import dateHelper from "Utilities/date-helper";
//  types
import { TableHeader, TableRow, headers } from "./VTableData";
import { ShapoliBreach } from "@/types/ShapoliBreach";

@Component({
  components: {},
})
export default class PowerLimitBreachesTable extends Vue {
  @Prop({ required: true }) breaches!: ShapoliBreach[];
  tableHeaders: TableHeader[] = headers;

  get tableRowItems(): TableRow[] {
    if (!this.breaches) return [];

    const tableRows: TableRow[] = [];
    this.breaches.forEach((item: TableRow) => {
      const row: TableRow = {
        id: item.id,
        acknowledgedDateUtc: item.acknowledgedDateUtc,
        silencedDateUtc: item.silencedDateUtc,
        startDateUtc: item.startDateUtc ? dateHelper.getFormatedDateTimeString(item.startDateUtc) : "",
        startPosition: item.startPosition,
        endDateUtc: item.endDateUtc ? dateHelper.getFormatedDateTimeString(item.endDateUtc) : "",
        endPosition: item.endPosition,
        reason: item.reason ? item.reason : "Unspecified",
        reasonUpdatedUtcTimeStamp: item.reasonUpdatedUtcTimeStamp ? dateHelper.getFormatedDateTimeString(item.reasonUpdatedUtcTimeStamp) : "",
        comment: item.comment,
      };
      tableRows.push(row);
    });
    return tableRows;
  }

  onRowClickEvent(breach: ShapoliBreach): void {
    this.$emit("onEventRowClicked", breach.id);
  }

  get isNoData(): boolean {
    return false;
  }

  customSort(items: [], sortBy: any, isDesc: any): [] {
    //do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //sort as numbers
    if (sortBy[0] === "id") {
      return items.sort((a: any, b: any) => {
        return isDesc[0]
          ? (Number(b[sortBy].replace(/\D/g, "")) || 0) < (Number(a[sortBy].replace(/\D/g, "")) || 0)
            ? -1
            : 1
          : (Number(a[sortBy].replace(/\D/g, "")) || 0) < (Number(b[sortBy].replace(/\D/g, "")) || 0)
          ? -1
          : 1;
      });
    }

    //sort as dates
    if (sortBy[0] === "startDateUtc" || sortBy[0] === "endDateUtc" || sortBy[0] === "reasonUpdatedUtcTimeStamp") {
      return items.sort((a: any, b: any) => {
        const previous = dateHelper.getDateInMilliseconds(a[sortBy]);
        const next = dateHelper.getDateInMilliseconds(b[sortBy]);

        return isDesc[0] ? ((next || 0) < (previous || 0) ? -1 : 1) : (previous || 0) < (next || 0) ? -1 : 1;
      });
    }

    //sort all other columns as strings
    return items.sort((a: any, b: any) => {
      if (isDesc[0]) return b[sortBy] < a[sortBy] ? -1 : 1;

      return a[sortBy] < b[sortBy] ? -1 : 1;
    });
  }
}
