






















import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {},
})
export default class PowerLimitBreachesActions extends Vue {
  selectedYear: number = new Date().getFullYear();
  loading = false;

  get lastYears(): number[] {
    // return years until 2019 including
    const currentYear = new Date().getFullYear();
    const lastYears: number[] = [currentYear];
    const yearsUntil2019 = new Date().getFullYear() - 2019;
    for (let i = 1; i <= yearsUntil2019; i++) {
      lastYears.push(currentYear - i);
    }
    return lastYears;
  }

  onYearChangeEvent(year: number | null): void {
    this.$emit("onYearChange", year);
  }

  downloadBreachesYearReportEvent(): void {
    this.$emit("onDownloadBreachesYearReportClicked");
  }
}
