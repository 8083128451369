
export interface TableHeader {
  text: string | undefined;
  value: string;
  sortable?: boolean;
  width?: string;
}

export interface TableRow {
  utcTimestamp: string;
  eventType: string;
  message: string;
}
export const headers: TableHeader[] = [
  {
    text: "Timestamp (UTC)",
    value: "utcTimestamp",
    sortable: true,
    width: "207px",
  },
  {
    text: "Type",
    value: "eventType",
    sortable: true,
    width: "207px",
  },
  {
    text: "Description",
    value: "message",
    sortable: true,
    width: "669px",
  },
];
