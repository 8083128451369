








































import { Component, Vue, Prop } from "vue-property-decorator";
import dateHelper from "Utilities/date-helper";
import { ShapoliEvent } from "@/types/ShapoliEvent";
import { TableRow, headers } from "./VTableDataEvents";

@Component({
  components: {},
})
export default class Events extends Vue {
  @Prop({ required: true }) events!: ShapoliEvent[];
  tableHeaders = headers;

  get tableRowItems(): TableRow[] {
    if (!this.events?.length) return [];

    const tableRows: TableRow[] = [];
    this.events.forEach((item: TableRow) => {
      const row: TableRow = {
        utcTimestamp: item.utcTimestamp ? dateHelper.getFormatedDateTimeString(item.utcTimestamp) : "",
        eventType: item.eventType,
        message: item.message,
      };
      tableRows.push(row);
    });
    return tableRows;
  }

  get isNoData(): boolean {
    return Boolean(this.events && this.events.length === 0);
  }

  customSort(items: [], sortBy: any, isDesc: any): [] {
    //do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //sort as dates
    if (sortBy[0] === "utcTimestamp") {
      return items.sort((a: any, b: any) => {
        const previous = dateHelper.getDateInMilliseconds(a[sortBy]);
        const next = dateHelper.getDateInMilliseconds(b[sortBy]);

        return isDesc[0] ? ((next || 0) < (previous || 0) ? -1 : 1) : (previous || 0) < (next || 0) ? -1 : 1;
      });
    }

    //sort all other columns as strings
    return items.sort((a: any, b: any) => {
      if (isDesc[0]) return b[sortBy] < a[sortBy] ? -1 : 1;

      return a[sortBy] < b[sortBy] ? -1 : 1;
    });
  }
}
